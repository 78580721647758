<template>
<div>


  <div style="width: 100%;display: flex;flex-direction: row;justify-items: flex-start;align-items: center">
    <el-select v-model="machine" placeholder="请选择机组" style="margin: 8px" >
      <el-option
        v-for="item in machines"
        :key="item"
        :label="item.name"
        :value="item.id">
      </el-option>
    </el-select>
    <el-date-picker
      value-format="yyyy-MM-dd"
      v-model="value2"
      type="daterange"
      align="right"
      unlink-panels
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      :picker-options="pickerOptions">
    </el-date-picker>
    <el-button type="primary" style="margin: 8px" @click="getData">查询</el-button>
  </div>

  <div id="main" style="width: 100%;height:600px;margin-top: 50px;font-size: 16px">

  </div>
</div>
</template>

<script>
import enums from "../../js/enums";
import clientUtils from "../../js/clientUtils";
import api from "@/js/api";
export default {
  name: "linepic",
  data(){
    return{
      option :{

        title: {
          text: '历史数据曲线'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: [
            '一网供温',
            '一网回温',
            '一网供压',
            '一网回压',
            '二网供温',
            '二网回温',
            '二网供压',
            '二网回压',
            '一阀给定',
            '二阀给定',
            '循环泵给定',
            '室外气温'
          ]
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [

          {
            name:'一网供温',type:'line',data: []
          },
          {
            name:'一网回温',type:'line',data: []
          },
          {
            name:'一网供压',type:'line',data: []
          },
          {
            name:'一网回压',type:'line',data: []
          },
          {
            name:'二网供温',type:'line',data: []
          },
          {
            name:'二网回温',type:'line',data: []
          },
          {
            name:'二网供压',type:'line',data: []
          },
          {
            name:'二网回压',type:'line',data: []
          },
          {
            name:'一阀给定',type:'line',data: []
          },
          {
            name:'二阀给定',type:'line',data: []
          },
          {
            name:'循环泵给定',type:'line',data: []
          },
          {
            name:'室外气温',type:'line',data: []
          }
        ]
      },
      machines:[],      //机组筛选框
      times:[],          //时间筛选框,
      machine:""
      ,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      value2: ''
    }
  },
  components: {

  },
  filters: {
    dateFormater: function (datetime) {
      return clientUtils.formatDatetime(datetime, enums.datetimeFormat.FULL_CHS)
    }
  },
  methods:{
    loadAllMachine: function () {
      const that = this;
      clientUtils.get(api.GET_ALL_MACHINE, function (res) {
        if (res.data) {
          that.machines = res.data;
        }
      });
    },
    getData:function (){
      let time = this.value2.toString().split(",")
      let start = time[0]
      let stop = time[1]
      const that = this
      clientUtils.get("/system/networkData/selectHeatDataHistoryForLine?machineId="+this.machine+"&startTime="+start+"&stopTime="+stop,function (res) {
        if (res.data!=null){
          that.$message({
            message: '数据更新成功',
            type: 'success'
          });
          that.option.xAxis.data.length = 0

          for (let i = 0; i < res.data.data.length; i++) {

            //设置x轴，for循环push进去

            that.option.xAxis.data.push(res.data.data[i].gmtCreate)
            that.option.series[0].data.push(res.data.data[i].firstSuTempature)      //一网供温
            that.option.series[1].data.push(res.data.data[i].firstReTempature)      //一网回温
            that.option.series[2].data.push(res.data.data[i].firstSuPressure)       //一网供压
            that.option.series[3].data.push(res.data.data[i].firstRePressure)       //一网回压
            that.option.series[4].data.push(res.data.data[i].secSuTempature)        //二网供回温度
            that.option.series[5].data.push(res.data.data[i].secReTempature)
            that.option.series[6].data.push(res.data.data[i].secSuPressure)         // 二网供回压
            that.option.series[7].data.push(res.data.data[i].secRePressure)
            that.option.series[8].data.push(res.data.data[i].mainValveSet)        //一阀给定
            that.option.series[9].data.push(res.data.data[i].auxValveSet)         //二阀给定
            that.option.series[10].data.push(res.data.data[i].circulatingPumpSet)
            that.option.series[11].data.push(res.data.data[i].roomTempatureAvg)   //室外气温
          }
          var echarts = require('echarts');
          var myChart = echarts.init(document.getElementById('main'));
          myChart.setOption(that.option);
        }
      })
    }
  },
  mounted() {
    this.machine =  this.$route.params.stationId

    // this.getLinedata(id)
    this.loadAllMachine()
  }
}
</script>

<style scoped>

</style>
